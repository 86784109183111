<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p
          v-if="currentUser"
          class="user-name font-weight-bolder mb-0"
        >
          {{ currentUser.name || currentUser.email }}
        </p>
        <span
          v-if="currentUser"
          class="user-status"
        >{{ currentUser.userType }}</span>
      </div>
      <b-avatar
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          icon="ChevronDownIcon"
          size="22"
        />
      </b-avatar>
      <b-avatar
        style="display:none"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      v-if="currentUser"
      :to="`/myProfile/${currentUser.id}`"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import firebase from 'firebase'
import { mapState } from 'vuex'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: {
        id: 1,
        fullName: 'John Doe',
        username: 'johndoe',
        password: 'admin',
        // eslint-disable-next-line global-require
        avatar: require('@/assets/images/avatars/13-small.png'),
        email: 'admin@demo.com',
        role: 'admin',
        ability: [
          {
            action: 'manage',
            subject: 'all',
          },
        ],
        extras: {
          eCommerceCartItemsCount: 5,
        },
      },
      avatarText,
    }
  },
  computed: {
    ...mapState('user', ['currentUser']),
  },
  methods: {
    logout() {
      // Logout
      firebase.auth().signOut()
      this.$router.go('/login')
    },
  },
}
</script>
